import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="Footer" className="w-full bg-black text-slate-50 py-6">
      <div className="w-full max-w-screen-xl mx-auto flex flex-col items-center">
        <div className="w-full flex flex-col md:flex-row justify-between items-center mb-4">
          <div className="text-sm font-light mb-4 md:mb-0 text-center md:text-left">
            <p>AlphaQuark, Awfis, 10th floor, Tower B,</p>
            <p>Prestige Shantiniketan, Whitefield,</p>
            <p>Bengaluru - 560048</p>
            <p className="mt-1 text-xs italic text-slate-400">AlphaQuark brand name is operated under Arpint Pvt Ltd</p>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-8">
            <a
              // href="https://calendly.com/gopesh-iitk/30min"
              href= "https://calendly.com/gopesh-alphaquark/30min"
              target="_blank"
              rel="noreferrer noopener"
              className="text-base font-Poppins cursor-pointer leading-6 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150"
            >
              Contact us
            </a>

            <Link to="/terms-of-services" className="text-base font-Poppins cursor-pointer leading-6 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
              Terms of Service
            </Link>
            <Link to="/disclaimer" className="text-base font-Poppins cursor-pointer leading-6 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
              Disclaimer
            </Link>
            <Link to="/PrivacyPolicy" className="text-base font-Poppins cursor-pointer leading-6 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
            Privacy Policy
            </Link>
            <Link to="/RefundCancellation" className="text-base font-Poppins cursor-pointer leading-6 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
            Refund & Cancellation
            </Link>
            <Link to="/ShippingAndExchangepolicy" className="text-base font-Poppins cursor-pointer leading-6 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
            Shipping And Exchange Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;