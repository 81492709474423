import React from 'react'
import Navbar from "./HeroSection/Navbar"
import Footer from "./Footer/Footer"

export default function  ShippingAndExchangePolicy  () {
  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      <main className="bg-zinc-900 py-12 md:py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto pt-8 sm:pt-0">
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-8">Shipping and Exchange Policy</h1>
    
          <section className="mb-12">
            <h2 className="text-xl font-semibold mb-4">1. Shipping Policy</h2>
            <p className="mb-4 text-base md:text-lg">
            As a digital product, our software is delivered electronically via the internet. Therefore, no physical shipping is required or applicable. Upon successful purchase, you will gain access to the software through a download link or an online account, depending on the nature of your purchase. 
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-xl font-semibold mb-4">2. Exchange Policy</h2>
            <p className="mb-4 text-base md:text-lg">
            Due to the intangible nature of our product, all sales are final once the transaction is completed. We do not offer exchanges for our software. We encourage customers to carefully review all product descriptions, system requirements, and other relevant information before making a purchase decision.
            </p>
            <p className="mb-4 text-base md:text-lg">
            To help ensure the product meets your needs, we recommend that you take advantage of any free trials, demos, or product previews we may offer. These provide a risk-free way to evaluate our software before committing to a full purchase.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-xl font-semibold mb-4">3. Commitment to Quality</h2>
            <p className="mb-4 text-base md:text-lg">
            We are dedicated to delivering high-quality software and exceptional customer service. If you encounter any technical issues or have questions about the product after your purchase, please contact our support team for assistance. We will do our best to resolve any issues promptly, but please note that refunds or exchanges are not available once the sale is complete.
            </p>
            <p className="mb-4 text-base md:text-lg">
            By purchasing our software, you acknowledge and accept the terms outlined in this Shipping and Exchange Policy.            </p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}


